<template>
  <section>
    <section class="main-header">
      <div class="overlay"></div>
      <div class="container d-flex align-items-center">
        <h1>EXPLORE OUR OPEN JOB OPPORTUNITIES</h1>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <!--         <form @submit.prevent class="search-portal">
          <div class="d-flex">
            <div class="form-group">
              <VueMultiselect
                v-model="keyWords"
                placeholder="Competences"
                label="name"
                :options="getCompetencesFilterList"
                :reduce="(competence) => competence"
                :clearable="true"
                multiple
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching && search.length >= 3">
                    No results found for <em>{{ search }}</em
                    >.
                  </template>
                  <div style="opacity: 0.5" v-else>Please type at least 3 characters</div>
                </template>
              </VueMultiselect>
            </div>
            <div class="form-group">
              <VueMultiselect
                @search="fetchOptions(sendLocation, true)"
                v-model="sendLocation"
                class="mr-1 w-100"
                label="name"
                placeholder="Location"
                aria-label="location"
                aria-describedby="location-input"
                :options="[...locationsOptions]"
                :reduce="(location) => location"
                :clearable="false"
                multiple
              >
                <template v-slot:no-options>
                  <div style="opacity: 0.5">Please type at least 3 characters</div>
                </template>
              </VueMultiselect>
            </div>
            <div class="form-group">
              <button @click="searchJob(1)" class="btn btn-secondary">Search Jobs</button>
            </div>
          </div>
        </form> -->
        <div class="col-md-12 latest-ads">
          <!-- Single Job Post -->
          <div v-for="jobAd in jobAds" :key="jobAd.id" class="single d-flex align-items-center">
            <div class="job-info">
              <a :href="`https://jobs.jobshark.eu/jobs/${jobAd.slug}`" target="_blank">{{
                jobAd.title
              }}</a>
            </div>
            <div class="location">
              <i class="fas fa-map-marker-alt"></i>
              <span>{{ jobAd.address.city.name }}</span>
              <span>{{ jobAd.address.country.name }}</span>
            </div>
            <div class="location">
              <i class="fas fa-building"></i>
              <span>{{ employmentType(jobAd.employment_type) }}</span>
            </div>
            <div v-if="() => jobAd.hasOwnProperty('required_competences')" class="tagline ml-auto">
              <a
                v-for="competence in jobAd.required_competences.slice(0, 4)"
                :key="competence.id"
                href="#"
                >{{ competence.name }}</a
              >
            </div>
          </div>
          <!-- End Single Job Post -->
        </div>
      </div>
      <div class="d-flex justify-content-center copyright">
        <div class="text">
          <span>Powered by</span>
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 143.91 129.01"
          >
            <title>jobshark_logo_white</title>
            <path
              d="M45.8,158.64a5.93,5.93,0,0,1-.37,2.13,4.47,4.47,0,0,1-1.08,1.65,4.83,4.83,0,0,1-1.76,1.06,7.31,7.31,0,0,1-2.45.37,8,8,0,0,1-2-.24A7.72,7.72,0,0,1,36.5,163v-3a4.33,4.33,0,0,0,.74.5,6.61,6.61,0,0,0,1.9.66,5.41,5.41,0,0,0,1,.1,2.71,2.71,0,0,0,.87-.15,2.07,2.07,0,0,0,.81-.51,2.71,2.71,0,0,0,.58-.93,3.9,3.9,0,0,0,.23-1.43v-8.62h-6V147H45.8Z"
              transform="translate(-27.12 -34.87)"
              style="fill: rgb(242, 101, 34)"
            />
            <path
              d="M64.36,155.24a12.78,12.78,0,0,1-.53,3.89,7.54,7.54,0,0,1-1.45,2.69,5.71,5.71,0,0,1-2.16,1.56,6.9,6.9,0,0,1-2.64.51,5.79,5.79,0,0,1-4.92-2.17A10.33,10.33,0,0,1,51,155.39a12.77,12.77,0,0,1,.53-3.89A7.55,7.55,0,0,1,53,148.81a5.73,5.73,0,0,1,2.16-1.56,6.9,6.9,0,0,1,2.64-.51,5.79,5.79,0,0,1,4.92,2.17Q64.36,151.08,64.36,155.24Zm-3.3.15a9.5,9.5,0,0,0-.79-4.39,2.72,2.72,0,0,0-2.59-1.45,3,3,0,0,0-1.53.37,2.9,2.9,0,0,0-1,1.1,5.57,5.57,0,0,0-.6,1.78,13.84,13.84,0,0,0-.19,2.43,9.43,9.43,0,0,0,.79,4.39,2.72,2.72,0,0,0,2.58,1.45,3,3,0,0,0,1.54-.37,2.85,2.85,0,0,0,1-1.1,5.7,5.7,0,0,0,.6-1.78A13.87,13.87,0,0,0,61.06,155.39Z"
              transform="translate(-27.12 -34.87)"
              style="fill: rgb(242, 101, 34)"
            />
            <path
              d="M68.41,163.59V147h5.35A7.42,7.42,0,0,1,78,148a3.41,3.41,0,0,1,1.41,3,5,5,0,0,1-.15,1.21,3.79,3.79,0,0,1-.45,1.08,3.56,3.56,0,0,1-.76.88,3.4,3.4,0,0,1-1.08.6,3.89,3.89,0,0,1,1.19.4,3.51,3.51,0,0,1,1.66,1.9,4,4,0,0,1,.25,1.45,4.46,4.46,0,0,1-1.7,3.81,8.08,8.08,0,0,1-4.91,1.27Zm7.76-12a2.27,2.27,0,0,0-.15-.85,1.56,1.56,0,0,0-.49-.65,2.41,2.41,0,0,0-.86-.41,4.85,4.85,0,0,0-1.27-.15H71.5v4.39h1.89a3.92,3.92,0,0,0,1.29-.18,2.32,2.32,0,0,0,.86-.51,1.88,1.88,0,0,0,.48-.74A2.72,2.72,0,0,0,76.17,151.55ZM73.68,161a4.11,4.11,0,0,0,1.31-.19,2.71,2.71,0,0,0,.94-.53,2.22,2.22,0,0,0,.58-.79,2.45,2.45,0,0,0,.2-1,2,2,0,0,0-.81-1.69,4.08,4.08,0,0,0-2.47-.61H71.5V161Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #f26522"
            />
            <path
              d="M95.93,158.59a5.22,5.22,0,0,1-.5,2.36A4.51,4.51,0,0,1,94,162.6a6.22,6.22,0,0,1-2.14,1,11,11,0,0,1-2.71.32,20.31,20.31,0,0,1-2.52-.15,14.84,14.84,0,0,1-2.41-.51v-3.08q.58.2,1.22.38t1.27.31q.64.13,1.27.21a10.33,10.33,0,0,0,1.23.08,6.43,6.43,0,0,0,1.51-.15,3.15,3.15,0,0,0,1-.42,1.62,1.62,0,0,0,.57-.63,1.84,1.84,0,0,0,.18-.81,1.53,1.53,0,0,0-.35-1,3.28,3.28,0,0,0-.92-.73,8.34,8.34,0,0,0-1.3-.57l-1.48-.54a16.21,16.21,0,0,1-1.48-.66,5.34,5.34,0,0,1-1.3-.91,4.24,4.24,0,0,1-.92-1.3,4.31,4.31,0,0,1-.35-1.82,4.88,4.88,0,0,1,.35-1.85,4,4,0,0,1,1.12-1.53,5.63,5.63,0,0,1,2-1,9.39,9.39,0,0,1,2.88-.39q.49,0,1,0l1.07.11q.53.07,1,.17l.89.2v2.85a9.28,9.28,0,0,0-.89-.28q-.48-.13-1-.22t-1-.15q-.52-.05-1-.05a6.23,6.23,0,0,0-1.4.13,3,3,0,0,0-.94.37,1.42,1.42,0,0,0-.53.58,1.74,1.74,0,0,0-.16.76,1.26,1.26,0,0,0,.35.89,3.35,3.35,0,0,0,.93.67,10.57,10.57,0,0,0,1.31.56l1.5.56a14.78,14.78,0,0,1,1.5.68,5.75,5.75,0,0,1,1.31.93,4.22,4.22,0,0,1,.93,1.29A4.1,4.1,0,0,1,95.93,158.59Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #a3a6a7"
            />
            <path
              d="M109.18,163.59v-7.21h-5.63v7.21h-3.12V147h3.12v6.66h5.63V147h3.12v16.57Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #a3a6a7"
            />
            <path
              d="M126.23,163.59l-.91-3.27h-5.78l-.94,3.27H115.5L120.41,147h4.36l4.91,16.57Zm-3.77-13.53-2.18,7.71h4.34Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #a3a6a7"
            />
            <path
              d="M141.67,163.59l-2.33-5.33a2.53,2.53,0,0,0-.92-1.15,2.48,2.48,0,0,0-1.39-.39h-.6v6.86h-3.12V147h4.83a12.12,12.12,0,0,1,2.49.23,5.17,5.17,0,0,1,1.9.78,3.62,3.62,0,0,1,1.21,1.42,4.88,4.88,0,0,1,.42,2.14,4.62,4.62,0,0,1-.27,1.62,3.62,3.62,0,0,1-.75,1.22,3.77,3.77,0,0,1-1.15.83,5.32,5.32,0,0,1-1.45.44,2.08,2.08,0,0,1,1.12.69,6.89,6.89,0,0,1,1,1.63l2.65,5.55Zm-.77-11.69a2,2,0,0,0-.77-1.75,4.11,4.11,0,0,0-2.32-.53h-1.37v4.68h1.27a5.25,5.25,0,0,0,1.31-.15,3,3,0,0,0,1-.46,2.09,2.09,0,0,0,.65-.75A2.25,2.25,0,0,0,140.9,151.9Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #a3a6a7"
            />
            <path
              d="M157.69,163.59l-5-8v8h-3.17V147h3.17v7.54l5-7.54h3.77l-5.63,7.9,5.92,8.67Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #a3a6a7"
            />
            <path
              d="M45,77.81c2.37-3,4.76-5.92,7.13-8.85C65.07,76.1,78,82.16,90.87,90.33,75.59,85.41,60.31,82.11,45,77.81Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M91.62,90q3.95-8.09,7.89-16.85Q99,64.81,98.44,56.58a322.31,322.31,0,0,1,22.91,27.35Q106.49,87.4,91.62,90Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M134.8,112.82a238.62,238.62,0,0,1-25.23.63c4.57-8.8,9.14-18.25,13.72-28.14C127.12,94.26,131,103.53,134.8,112.82Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M108.24,113.09c-5.3-7.85-10.59-15.22-15.88-22q14.82-2.46,29.63-5.85C117.42,95,112.83,104.39,108.24,113.09Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M67.44,131.91c11.27-3.83,22.54-6.45,33.81-10.43q3.15-3.49,6.3-7.26c-9.82-1.35-19.65-2.91-29.47-4C74.53,117.68,71,124.79,67.44,131.91Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M45,78.89c10.76,9.9,21.51,18.68,32.27,29.79,4.32-5.54,8.65-11.32,13-17.5C75.2,86.32,60.12,83,45,78.89Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M91.39,91.74c-4.33,6.25-8.65,12.07-13,17.63,9.43,1,18.86,2.42,28.3,3.68Q99.05,101.66,91.39,91.74Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M102.36,122.32c5,2.26,10.07,4.59,15.11,6.85-2.85-4.94-5.7-9.79-8.55-14.48Q105.63,118.65,102.36,122.32Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M149.2,85.48q-12.32-.09-24.63-.25c3.81,8.94,7.63,18.18,11.45,27.42,4.39-8.93,8.79-18.16,13.18-27.17Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M171,86.92c-6.84-.28-13.68-.73-20.53-1.23-4.37,8.95-8.74,18.14-13.1,27.08C148.62,104.54,159.82,94.74,171,86.92Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M139.46,112.93c6.38-4.78,12.75-10,19.13-15.05,3.61.62,7.22,1.11,10.83,1.42C159.43,104.21,149.44,109.08,139.46,112.93Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M49.21,36.76a61.18,61.18,0,0,1-9.46-1.89c2.41,5.66,4.83,11,7.24,16Q48.1,43.8,49.21,36.76Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M44.44,67.78a116.81,116.81,0,0,1-17.33-5.22,136.56,136.56,0,0,0,16,13.72Q43.79,72,44.44,67.78Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M49.23,45.23C47.58,55.66,45.94,66.18,44.3,76.9c2.35-3,4.7-6,7.05-9q-.43-4.83-.87-9.67Q49.85,51.75,49.23,45.23Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M49.69,36.82q.72,7.45,1.44,14.84A199.23,199.23,0,0,0,69,36.47,79.91,79.91,0,0,1,49.69,36.82Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
            <path
              d="M51.94,60.53l.58,6c.06.5.13,1,.19,1.52,2.87,1.67,5.75,3.25,8.61,4.81C58.2,68.78,55.07,64.74,51.94,60.53Z"
              transform="translate(-27.12 -34.87)"
              style="fill: #999"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
/* import VueMultiselect from 'vue-multiselect'; */
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Open positions | Comstream',
  components: {
    /*    VueMultiselect, */
  },
  data() {
    return {
      jobAds: [],
      keyWords: [],
      getCompetencesFilterList: [],
      sendLocation: [],
      locationsOptions: [],
    };
  },
  methods: {
    fetchOptions(search, loading) {
      if (search.length < 3) {
        this.locationsOptions = [];
      }

      if (search.length >= 3) {
        loading(true);

        axios(`https://jobshark.eu/api/v1/common/address/searchlocation/${search}`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => {
            const citiesList = response.data.cities;
            const countriesList = response.data.countries;
            const concatedLists = [].concat(countriesList, citiesList);
            this.locationsOptions = concatedLists;
            loading(false);
          })
          .catch((err) => {
            loading(false);
            this.$toasted.error(err, {
              theme: 'bubble',
              icon: 'fa-times-circle',
              position: 'bottom-right',
              duration: 8000,
            });
          });
      }
    },
    getLatestJobAds() {
      axios
        .get(`https://jobshark.eu/api/v1/externalpages/topjobads`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((data) => {
          this.jobAds = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchJob(currentPage) {
      axios(`https://jobshark.eu/api/v1/externalpages/search`, {
        method: 'post',
        data: {
          page: currentPage,
          count: 100,
          /*        employers: ['bc6c262f-460d-4992-b01b-63c39522e75e'], */
          comstream: true,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          this.jobAds = response.data.data;
          this.totalPages = response.data.last_page;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    employmentType(employment) {
      if (employment === 1) {
        return 'Employment';
      } else if (employment === 2) {
        return 'Self-employment';
      } else if (employment === 3) {
        return 'Employment / Self-Employment';
      }
    },
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    this.searchJob(1);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');
.main-header {
  position: relative;
  height: 40vh;
  background: url('https://comstream.eu/img/homepage/header_home.jpg');
  background-size: cover;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, #000);
    z-index: 0;
  }
  .container {
    height: 40vh;
    flex-wrap: wrap;
    h1 {
      font-family: 'Raleway', sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.5rem;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      padding: 12px 20px;
      letter-spacing: 8px;
      width: 80%;
      margin: 12px auto 30px auto;
      text-align: center;
      z-index: 999;
      span {
        display: block;
      }
    }
  }
}
.search-portal {
  padding: 0;
  margin: 40px 0 0 0;
  .form-group {
    position: relative;
    width: 100%;
    display: block;
    i {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 32px;
      color: #9b9b9b;
      font-size: 26px;
    }
    .form-control {
      border-radius: 8px;
      min-height: 65px;
      flex: 1;
      font-size: 18px;
      width: 100%;
    }
    .btn-secondary {
      height: 46px;
      border-radius: 3px;
      display: block;
      width: 100%;
      text-transform: uppercase;
      margin-bottom: 12px;
      background-color: #a93224;
      border-color: #a93224;
    }
    &:first-of-type {
      width: 50%;
      flex-shrink: 0;
      margin-right: 12px;
    }
    &:nth-of-type(2) {
      margin-right: 12px;
    }
  }
  a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-top: 10px;
  }
}
.latest-ads {
  padding: 60px 0;

  h2 {
    text-transform: uppercase;
    font-size: 21px;
    margin-bottom: 40px;
  }

  .single {
    padding: 5px;
    background: #fff;
    box-shadow: 0px 15px 35px #0000000d;
    margin-bottom: 20px;

    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      object-position: center;
      overflow: hidden;
      margin-right: 12px;
      flex-shrink: 0;
    }

    .job-info {
      width: 37%;
      margin-right: 12px;

      a {
        display: block;
        text-decoration: none;

        &:first-of-type {
          font-size: 18px;
        }

        &:nth-of-type(2) {
          color: #424242;
          font-size: 16px;
        }
      }
    }

    .location {
      text-align: center;
      width: 175px;
      margin: 0 10px;

      a {
        text-decoration: none;
      }

      i {
        display: block;
        color: #afafaf;
        font-size: 18px;
        margin-bottom: 5px;
      }

      span {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-transform: uppercase;
      }
    }

    .tagline {
      margin-left: auto;
      text-align: right;
      width: 200px;

      a {
        display: inline-block;
        background: #f2f2f2;
        border-radius: 16px;
        color: #3e3e3e;
        border: 5px solid #fff;
        font-size: 13px;
        padding: 3px 8px;
        text-decoration: none;
        font-weight: 500;
      }
    }
  }
}

.copyright {
  margin-bottom: 30px;
  text-align: center;
  svg {
    width: 60px;
    height: 50px;
  }
  .text {
    margin-left: 12px;
    span:first-of-type {
      display: block;
      font-size: 13px;
    }
    span:nth-of-type(2) {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 576px) {
  .main-header {
    .container {
      h1 {
        font-size: 24px;
        width: 100%;
      }
    }
  }
  .latest-ads {
    .single {
      display: block !important;
      padding: 6px 12px;
      .job-info {
        width: 100%;
        margin: 0 0 12px 0 !important;
      }
      .location {
        width: 100%;
        text-align: left;
        margin-left: 0;
        span {
          display: inline-block;
          margin-right: 8px;
        }
        i {
          display: none;
        }
      }
      .tagline {
        width: 100%;
        text-align: left;
      }
    }
  }
}
</style>
